export default {
	// 获取页面数据
	getTableData() {
		
		this.getClient();
		this.getLogistics();
		this.getProduct();
	},


	//获取所有客户
	getClient(){
		let  params = {
			account_type_id:3,
			page: 1,
			limit: 10000000
		}
		this.axiosPost("Client/clientList", params, function(res) {
			// console.log(res);
			if(res.data.code === 200) {
				this.opt = res.data.data.data;
				// console.log(this.opt1);
			}
		})
	},

	//获取产品
	getProduct(){
		let  params = {
			// client_id:val,
			page: 1,
			limit: 10000000
		}
		this.axiosPost('Product/list', params , res => {
			if(res.data.code === 200) {
				this.opt1 = res.data.data.data;
			}
		})
	},

	//获取所有物流
	getLogistics(){
		let  params = {}
		this.axiosPost("Public/logisticsList", params, function(res) {
			// console.log(res);
			if(res.data.code === 200) {
				this.opt2 = res.data.data;
				// console.log(this.opt1);
			}
		})
	},



	//添加
	add() {
		if(!this.form.client_id) {
			this.msgError('请选择客户!')
			return;
		}
		if(!this.form.logistics_odds) {
			this.msgError('请输入物流单号!')
			return;
		}
		if(!this.form.product_id) {
			this.msgError('请选择产品!')
			return;
		}
		if(!this.form.logistics_id) {
			this.msgError('请选择物流公司!')
			return;
		}
		
		let params = this.form;
		console.log(params);
		// params.status = 1;
		
		this.onLoad(true);
		this.axiosPost("LogisticsManagement/add" , params , res => {
			if(res.data.code == 200) {
				this.msgSuccess(res.data.message)
				this.onLoad(false);
				this.form = {
					client_id:'',
					logistics_odds:'',
					product_id:'',
					logistics_id:''
				}
			} else {
				this.msgError(res.data.mesage)
			}
		})
	},


}
