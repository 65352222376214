<template>
  <div>
    <div
      class="flex justify_content_between align_items_center content-wrapper p_lr_20 m_b_20"
    >
      <div>
        <h2>物流单增加</h2>
      </div>
    </div>

    <div class="content-wrapper p_20">
      <el-form :inline="true"  label-position="top">
        <el-form-item style="width: 35%;" label="1.选择客户：">
          <el-select v-model="form.client_id" @change="getProduct" size="small">
            <el-option 
							v-for="item in opt"
							:key="item.id"
							:value="item.id"
							:label="item.name"
						></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="2.输入需要跟踪的物流单面单号多个面单号以一行一个数据分割（全部面单号只可属于一个快递公司）"
        >
          <el-input type="textarea" :rows="5" class="w_400" v-model="form.logistics_odds"></el-input>
        </el-form-item>
        <el-form-item style="width: 35%;" label="3.选择产品：">
          <el-select v-model="form.product_id" size="small">
            <el-option 
							v-for="item in opt1"
							:key="item.id"
							:value="item.id"
							:label="item.name"
						></el-option>
          </el-select>
        </el-form-item>
        <el-form-item  style="width: 40%;" label="4.选择以上物流面单的快递公司：">
          <el-select v-model="form.logistics_id" size="small">
            <el-option 
							v-for="item in opt2"
							:key="item.id"
							:value="item.id"
							:label="item.logistics_name"
						></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="width: 100%; text-align: center;margin-top: 25px;" >
          <el-button type="primary" @click="add">确 定</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script type="text/javascript">
import methods from "./method";

export default {
  data() {
    return {
      form:{
        client_id:'',
        logistics_odds:'',
        product_id:'',
        logistics_id:''
      },
      opt:[],
      opt1:[],
      opt2:[]
    };
  },
  methods: methods,
  mounted() {
    this.getTableData();
  },
};
</script>
